import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import Icon from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Icon"

const Button = ({
  children,
  link,
  externalIcon,
  icon,
  className,
  target,
  btnType = "primary",
  ...props
}) => {
  const baseClasses = "btn group "
  const typeClasses = {
    "": "",
    primary: "btn-primary",
    secondary: "btn-secondary",
    tertiary: "btn-tertiary",
    outline: "btn-outline",
  }

  if (link) {
    return (
      <Link
        className={`${baseClasses} ${typeClasses[btnType]} ${
          className ? className : ""
        }`}
        link={link}
        externalIcon={externalIcon}
        target={target}
        {...props}
      >
        {icon !== undefined && <Icon icon={icon} size="1x" className="mr-2" />}
        {children}
      </Link>
    )
  } else {
    return (
      <button
        className={`${baseClasses} ${typeClasses[btnType]} ${
          className ? className : ""
        }`}
        {...props}
      >
        {icon !== undefined && <Icon icon={icon} size="1x" className="mr-2" />}
        {children}
      </button>
    )
  }
}

export default Button
